import {
  Injectable,
  NgZone,
  signal,
  Signal,
  WritableSignal,
} from '@angular/core';
import {
  FeatureFlagDto,
  FeaturesClient,
} from '@cloudmed/resolution-services-api-client';
import { filter } from 'rxjs';

import { poll } from '../../utils/global.utils';

import { FEATURE_FLAGS_POLLING_CYCLE } from '../../models';

/*
  Usage in Component: 
      import {
        FeatureFlagsService,
      } from 'src/app/shared/services/feature-flags/feature-flags.service';

      feature1Signal: Signal<boolean> | undefined = undefined;

      ngOnInit() {
        this.feature1Signal = this.featureFlagsService.getFeatureFlagSignal(
          FeatureFlagsService.apiFeatureFlags['DocumentCreationSubmissionMethod']
        );
      }

      Template...
        @if (feature1Signal && feature1Signal()) {
*/

@Injectable({ providedIn: 'root' })
export class FeatureFlagsService {
  public static readonly apiFeatureFlags: { [key: string]: string } = {
    // Example:
    // DocumentCreationSubmissionMethod: 'document_creation_submission_method',
  };

  private signalMap: Map<string, WritableSignal<boolean>> = new Map();

  private readonly featureFlagsPoller = poll(
    // whatToPoll
    () => {
      this.ngZone.runOutsideAngular(() => {
        this.loadFeatureFlags();
      });
    },
    // polling cycle
    FEATURE_FLAGS_POLLING_CYCLE
  );

  constructor(
    private readonly featuresClient: FeaturesClient,
    private readonly ngZone: NgZone
  ) {}

  public start() {
    if (Object.keys(FeatureFlagsService.apiFeatureFlags).length === 0) {
      console.warn(
        'no feature flags are used in the UI code, polling will not be run'
      );
      return;
    }
    this.featureFlagsPoller.start();
    this.loadFeatureFlags();
  }

  public getFeatureFlagSignal(featureFlagName: string): Signal<boolean> {
    let featureFlagSignal = this.signalMap.get(featureFlagName);
    if (!featureFlagSignal) {
      featureFlagSignal = signal<boolean>(false);
      this.signalMap.set(featureFlagName, featureFlagSignal);
    }
    return featureFlagSignal.asReadonly();
  }

  private mapFeatureFlagsToSignals(featureFlags: FeatureFlagDto[]) {
    featureFlags.forEach((featureFlag) => {
      if (!this.signalMap.has(featureFlag.name ?? '')) {
        this.signalMap.set(
          featureFlag.name ?? '',
          signal<boolean>(featureFlag.isEnabled ?? false)
        );
      } else {
        this.signalMap
          .get(featureFlag.name ?? '')
          ?.set(featureFlag.isEnabled ?? false);
      }
    });
    this.signalMap.forEach((signal, key) => {
      const matches = featureFlags.filter(
        (featureFlag) => featureFlag.name === key
      );
      if (matches.length === 0) {
        this.signalMap.get(key)?.set(false);
      }
    });
  }

  private loadFeatureFlags() {
    this.featuresClient
      .getFeatures()
      .pipe(filter((response: FeatureFlagDto[]) => !!response))
      .subscribe((items: FeatureFlagDto[]) => {
        this.mapFeatureFlagsToSignals(items);
      });
  }
}
