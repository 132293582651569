<div (mouseleave)="onContainerMouseLeave()">
  <p-menubar
    #menubarRef
    [autoDisplay]="true"
    [autoHide]="true"
    [model]="primeNgMenuItems()"
    styleClass="bg-r1-blue-800 text-white border-none rounded-none p-0 text-section-header px-3 gap-5 h-16"
  >
    <ng-template #start>
      <div>
        <a href="/">
          <img
            src="../../../../../../assets/R1_logo.svg"
            alt="R1 RCM"
            width="96"
            height="40"
          />
        </a>
      </div>
    </ng-template>

    <ng-template #item let-item let-root="root">
      @if (item.routerLink) {
        <a
          class="p-menubar-item-link flex items-center py-5 hover:bg-r1-blue-800/60 hover:text-white"
          [class.bg-r1-blue-800]="root && !menubarRef.mobileActive"
          [class.text-white]="root && !menubarRef.mobileActive"
          [routerLink]="item.routerLink"
          [routerLinkActive]="'p-menubar-item-link-active'"
          [target]="item.target"
        >
          <span>{{ item.label }}</span>
        </a>
      } @else {
        <a
          class="p-menubar-item-link flex items-center py-5 hover:bg-r1-blue-800/60 hover:text-white"
          [class.bg-r1-blue-800]="root && !menubarRef.mobileActive"
          [class.text-white]="root && !menubarRef.mobileActive"
          [href]="item.url"
          [target]="item.target"
        >
          <span>{{ item.label }}</span>
        </a>
      }
    </ng-template>

    <ng-template #end>
      @if (userInfo()?.fullName) {
        <div
          class="text-section-header inline-block h-10 w-10 cursor-pointer content-center justify-self-end rounded-full bg-white text-center text-r1-blue-800"
          (mouseenter)="overlayPanelRef.toggle($event)"
        >
          {{ userInfo()!.fullName! | firstLetters | uppercase }}
        </div>
      }

      <p-popover #overlayPanelRef>
        <ng-template #content>
          <div (mouseleave)="onUserProfileOverlayMouseLeave()" class="w-72">
            <ri-resolution-ui-user-profile
              [userProfileInfo]="userInfo()"
              (logout)="onLogout()"
            ></ri-resolution-ui-user-profile>
          </div>
        </ng-template>
      </p-popover>
    </ng-template>
  </p-menubar>
</div>
