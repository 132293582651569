import { definePreset } from '@primeng/themes';
import Lara from '@primeng/themes/lara';
// import Aura from '@primeng/themes/aura';

export const R1PrimeNgPreset = definePreset(Lara, {
  primitive: {
    'r1-blue': {
      50: '#ecf9ff',
      100: '#d4efff',
      200: '#b2e5ff',
      300: '#7dd7ff',
      400: '#40beff',
      500: '#149bff',
      600: '#0079ff',
      700: '#0061ff',
      800: '#0047bb',
      900: '#0845a0',
      950: '#0a2b61',
    },
  },
  semantic: {
    primary: {
      50: '{r1-blue.50}',
      100: '{r1-blue.100}',
      200: '{r1-blue.200}',
      300: '{r1-blue.300}',
      400: '{r1-blue.400}',
      500: '{r1-blue.500}',
      600: '{r1-blue.600}',
      700: '{r1-blue.700}',
      800: '{r1-blue.800}',
      900: '{r1-blue.900}',
      950: '{r1-blue.950}',
    },
    colorScheme: {
      light: {
        primary: {
          color: '{primary.800}',
          contrastColor: '#ffffff',
          hoverColor: '{primary.900}',
          activeColor: '{primary.950}',
        },
      },
    },
  },
  components: {
    tabs: {
      tabpanel: {
        padding: '0',
      },
    },
  },
});
