import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  inject,
  OnInit,
  signal,
  viewChild,
} from '@angular/core';
import { Menubar, MenubarModule } from 'primeng/menubar';
import { MenuItem as PrimeNgMenuItem } from 'primeng/api';
import {
  MenuItemsApiService,
  R1MenuItem,
} from '../../services/menu-items-api.service';
import { DOCUMENT, UpperCasePipe } from '@angular/common';
import {
  UserProfileComponent,
  UserProfileInfo,
} from '../user-profile/user-profile.component';
import { PopoverModule, Popover } from 'primeng/popover';
import { AuthService, IdToken, User } from '@auth0/auth0-angular';
import { deepEqual } from 'src/app/shared/utils/global.utils';
import { combineLatest, filter } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FirstLettersPipe } from '../../pipes/first-letters-from-word.pipe';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'ri-resolution-ui-header',
  imports: [
    MenubarModule,
    UserProfileComponent,
    PopoverModule,
    FirstLettersPipe,
    UpperCasePipe,
    RouterLink,
    RouterLinkActive,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  // Reference variables
  private readonly menubarRef = viewChild<Menubar>('menubarRef');
  private readonly overlayPanelRef = viewChild<Popover>('overlayPanelRef');

  // Signals
  private readonly _userInfo = signal<UserProfileInfo | null>(null);
  public readonly userInfo = computed(
    () => {
      return this._userInfo();
    },
    {
      equal: (a: UserProfileInfo | null, b: UserProfileInfo | null) => {
        return deepEqual(a, b);
      },
    }
  );

  public readonly primeNgMenuItems = computed<PrimeNgMenuItem[] | []>(() => {
    const apiMenuItems = this.menuItemsApiService.menuItems();
    if (!apiMenuItems) {
      return [];
    }
    return this.transformToType(apiMenuItems);
  });

  // Dependencies
  private readonly menuItemsApiService = inject(MenuItemsApiService);
  private readonly authService = inject(AuthService);
  private readonly document: Document = inject(DOCUMENT);
  private readonly destroyRef = inject(DestroyRef);

  // Other
  private readonly window = this.document.defaultView!; // Asserted in constructor

  constructor() {
    if (!this.window) {
      throw new Error(
        'window object is null, check environment where code is run'
      );
    }
  }

  ngOnInit() {
    this.extractUserDataFromAuthProvider();
  }

  onContainerMouseLeave(): void {
    const menubarRef = this.menubarRef();

    if (menubarRef) {
      menubarRef.hide();
    }
  }

  onUserProfileOverlayMouseLeave(): void {
    const overlayPanelRef = this.overlayPanelRef();
    if (overlayPanelRef) {
      overlayPanelRef.hide();
    }
  }

  onLogout() {
    this.authService.logout({
      logoutParams: { returnTo: location.origin, federated: false },
    });
  }

  private extractUserDataFromAuthProvider() {
    combineLatest({
      user: this.authService.user$,
      idToken: this.authService.idTokenClaims$,
    })
      .pipe(
        filter<{
          user: User | null | undefined;
          idToken: IdToken | null | undefined;
        }>(({ user }: { user: User | null | undefined }) => !!user),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(
        ({
          user,
          idToken,
        }: {
          user: User | null | undefined;
          idToken: IdToken | null | undefined;
        }) => {
          this._userInfo.set({
            fullName: idToken?.['displayName'] || user!.name,
            email: user?.email,
          });
        }
      );
  }

  private transformToType(apiMenuItems: R1MenuItem[]): PrimeNgMenuItem[] {
    function helper(
      this: HeaderComponent,
      items: R1MenuItem[]
    ): PrimeNgMenuItem[] {
      const result: PrimeNgMenuItem[] = [];

      for (const item of items) {
        let primeNgMenuItem: PrimeNgMenuItem = {
          label: item.label,
        };
        // Can't use URL.parse static method because it was added in typescript v5.6.2
        // But currently we have 5.4.5 and we can't update to 5.6.2+ because of peer
        // dependencies.
        // TODO (gorodnichev): After migration to angular v19, check the possibilty
        // of using URL.parse
        // const urlParts = URL.parse(item.url ?? '');

        // Manage menu url
        if (item.url && item.url.startsWith(this.window.location.origin)) {
          // If same origin then add routerLink to navigate using Angular Router
          primeNgMenuItem = {
            ...primeNgMenuItem,
            routerLink: item.url.replace(this.window.location.origin, ''),
          };
        } else {
          // External links open in a new tab
          primeNgMenuItem = {
            ...primeNgMenuItem,
            url: item.url ?? '',
            target: '_blank',
          };
        }

        // Manage subitems
        if (!!item.items) {
          primeNgMenuItem = {
            ...primeNgMenuItem,
            items: helper.call(this, item.items),
          };
        }

        result.push(primeNgMenuItem);
      }

      return result;
    }

    return helper.call(this, apiMenuItems);
  }
}
